export const environment = {
    production: true,
    version: require('../../package.json').version,
    apiURI: 'https://www.finmoliself.it/app/api',
    mvcURI: 'https://www.finmoliself.it/app/mvc',
    backofficeURI: 'https://backoffice.finmoliself.it/',
    websocketURI: 'https://www.finmoliself.it/app/websockets',
    environmentID: "PROD",
    documentRepository: {
        allowedExtensions: ['.pdf', '.png', '.jpg', '.jpeg', '.tif', '.tiff', '.txt', '.docx', '.xlsx', '.doc', '.xls', '.pptx', '.ppt', 'p7m', 'p7s', 'asics'],
        minFileSize: 1,
        maxFileSize: 10485760
    },
    rememberMachine: {
        name: 'SLSHL',
        expiringDays: 90,
        path: '',
        secure: false,
        sameSite: 'none'
    }
};
